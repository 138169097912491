<template>
    <div v-if="modules">
      <router-link
        v-for="(module, index) in modules"
        :key="index"
        :id="`card-module-${index}`"
        :to="module.link"
      >
        <div
          class="module-card"
        >
          <span
            class="module-title"
            >{{ module.moduleTitle }} </span
          >
          <p class="module-description">{{ module.title }}</p>
        </div>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "ColumnCard",
    props: {
      modules: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  
  <style style lang="scss" scoped>
  .module-card {
    background-color: var(--neutral-white);
    border-radius: 4px;
    padding: 1rem;
    margin: 0.5rem 0;
    box-sizing: border-box;
    border: 1px solid var(--bordercolor2);
  
    &:hover {
      border: 1px solid var(--maincolor);
    }
  }
  
  .module-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    color: var(--maincolor);
  }
  
  .module-description {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 0.5rem;
  }
  
  .module-footer,
  .module-footer-finished {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor);
  
    &-finished {
      color: var(--success);
    }
  }
  
  .icon-button,
  .icon-button-success {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
  
    ::v-deep .svgCss {
      svg {
        width: 1rem;
        height: 1rem;
      }
  
      path {
        fill: var(--maincolor) !important;
      }
    }
  }
  
  .week-arrows {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 1rem;
  
    ::v-deep .svgCss {
      svg {
        width: 2rem;
        height: 2rem;
      }
  
      path {
        fill: var(--maincolor) !important;
        cursor: pointer;
      }
    }
  }
  
  .icon-button-success ::v-deep .svgCss svg path {
    fill: var(--success) !important;
  }
  </style>
  